import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Dropdown, Space } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

// Actions
import { auth_token_remove } from '../../actions/authActions';
import { get_organization_info } from '../../actions/organizationActions';

// Utils
import isEmpty from '../../utils/isEmpty';

const Header = () => {
	const dispatch = useDispatch();

	const { user } = useSelector(state => state.auth);

	const { organization_info } = useSelector(state => state.organization);

	const _ls = { ...localStorage };

	useEffect(() => {
		dispatch(get_organization_info(user.organization));
	}, []);

	useEffect(() => {
		if (!isEmpty(organization_info)) {
			localStorage.setItem('organization_name', organization_info.name);
		}
	}, [organization_info]);

	const handleLogout = (e) => {
		e.preventDefault();
		dispatch(auth_token_remove());
	}

	const items = [
		{
			key: '0',
			label: <a href={process.env.REACT_APP_LOGIN + '/account'}>Cuenta</a>,
			icon: <i className='bi bi-person-circle dp-item-icon'></i>
		},
		{
			key: '1',
			label: <a href={process.env.REACT_APP_LOGIN + '/links'}>Regresar al menú</a>,
			icon: <i className='bi bi-grid dp-item-icon'></i>
		},
		{
			type: 'divider',
		},
		{
			key: '3',
			danger: true,
			label: <a onClick={(e) => handleLogout(e)}>Cerrar sesión</a>,
			icon: <i className='bi bi-box-arrow-right dp-item-icon'></i>
		}
	];

	return (
		<Navbar className='navbar' expand='lg' variant='dark'>
			<Container fluid>
				<Navbar.Brand href='/'>
					<img className='d-inline-block align-items-center me-2' height={'30px'} alt='Percepthor Logo'
						src={process.env.PUBLIC_URL + '/assets/percepthor-logo.png'}
					/>
					Percepthor - {_ls.organization_name}
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='navbarScroll' />
				<Navbar.Collapse id='navbarScroll'>
					<Nav className='me-auto' navbarScroll>
						{/* <Nav.Link href='/' active={window.location.pathname === '/'}>Buscar Logs</Nav.Link> */}
						<Nav.Link href='/view' active={window.location.pathname.includes('view')}>Ver Logs</Nav.Link>
					</Nav>
					<div className='header-dropdown'>
						<Dropdown
							menu={{ items }}
							trigger={[ 'click' ]}
							placement='bottom'
						>
							<a onClick={(e) => e.preventDefault()}>
								<Space>
									<i className='bi bi-person-circle'></i>{user.username}
									<CaretDownOutlined />
								</Space>
							</a>
						</Dropdown>
					</div>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default Header;
