import React, { useEffect, useState } from 'react';

// Packages
import { Space, Tag, Timeline, Typography } from 'antd';
import PropTypes from 'prop-types';

// Utils
import { setLogTypeColor } from '../../utils/logsTypes';

const LogsProcess = (props) => {
	const { processLogs } = props;
	
	const [logs, setLogs] = useState([]);

	const createLogsItems = (processLogs) => {
		let logs = [];

		processLogs.forEach((log, index) => {
			let logItem = {
				key: index,
				label: (
					<Typography.Text>{new Date(log.local_date).toLocaleString('es-MX', { hour12: true })}</Typography.Text>
				),
				children: (
					<Space direction='vertical' style={{ width: '600px' }}>
						<Space wrap>
							<Typography.Text strong>{log.title}</Typography.Text>
							<Tag color={setLogTypeColor(log.log_type)}>{log.log_type}</Tag>
						</Space>
						<Typography.Text type='secondary'>ID de Proceso: {log.process_id}</Typography.Text>
						<Typography.Text>Satus: {log.process_status}</Typography.Text>
					</Space>
				)
			};
			logs.push(logItem);
		});

		setLogs(logs);
	}

	useEffect(() => {
		createLogsItems(processLogs);
	}, [processLogs]);

	return (
		<Timeline
			mode='left'
			items={logs}
		/>
	)
}

LogsProcess.propTypes = {
	processLogs: PropTypes.array.isRequired
}

export default LogsProcess;
