import React, { useState } from 'react';

// Packages
import { message, Button, Col, Row, Typography, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import JSZip from 'jszip';

// Components
import LogsContentTable from './LogsContentTable';
import LogData from './LogData';

// Utils
import isEmpty from '../../utils/isEmpty';

const App = () => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [isFileAllowed, setIsFileAllowed] = useState(false);
	const [fileData, setFileData] = useState([]);
	const [logData, setLogData] = useState({});

	const allowedFileTypes = ['application/zip'];

	const checkFileType = (file) => {
		if (!allowedFileTypes.includes(file.type)) {
			message.error('Solo se permiten archivos ZIP.');
			setIsFileAllowed(false);
			return false;
		}
		else {
			setIsFileAllowed(true);
			return true;
		}
	};

	const handleFileChange = (info) => {
		if (info.file.status === 'done') {
			message.success(`${info.file.name} file uploaded successfully`);
			setSelectedFile(info.file.originFileObj);
		}
		else if (info.file.status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		}
	};

	const handleFileUpload = async () => {
		if (!selectedFile) {
			message.warning('Please upload a file first.');
			return;
		}

		const zip = new JSZip();

		try {
			const zipContent = await zip.loadAsync(selectedFile);

			const files = [];
			let fileCount = 0;

			zipContent.forEach((relativePath, file) => {
				file.async('string').then((content) => {
					files.push({
						key: fileCount++,
						name: relativePath,
						content: JSON.parse(content),
					});

					setFileData([...files]);
				});
			});
		} catch (error) {
			console.error('Error reading zip file:', error);
		}
	};

	return (
		<Row justify='center'>
			<Col span={16}>
				<Typography.Title level={2}>Visualización de Logs</Typography.Title>

				<div>
					<Upload.Dragger
						beforeUpload={checkFileType}
						onChange={handleFileChange}
						customRequest={({ onSuccess }) => onSuccess('ok')}
					>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />
						</p>
						<p className='ant-upload-text'>Haga clic o arrastre un archivo a esta área para cargarlo</p>
						<p className='ant-upload-hint'>Por favor, asegúrate de que el archivo que adjuntas sea de tipo ZIP.</p>
					</Upload.Dragger>
					{isFileAllowed && (
						<div className='upload-button'>
							<Button type='primary' onClick={handleFileUpload}>
								Unzip and Read
							</Button>
						</div>
					)}
				</div>

				{/* Logs Table */}
				{fileData.length > 0 && (
					<LogsContentTable
						logs={fileData}
						setLogData={setLogData}
					/>
				)}

				{!isEmpty(logData) && (
					<LogData
						logData={logData}
					/>
				)}
			</Col>
		</Row>
	);
};

export default App;