import { findType } from './findType';

const logTypeEng = {
	ERROR: 'Error',
	INFO: 'Information',
	NONE: 'None',
	PHOTO: 'Photo',
	SAVED: 'Saved',
	SUCCESS: 'Success',
	WARNING: 'Warning'
}

const logTypeSpa = {
	ERROR: 'Error',
	INFO: 'Información',
	NONE: 'Ninguno',
	PHOTO: 'Foto',
	SAVED: 'Guardado',
	SUCCESS: 'Éxito',
	WARNING: 'Advertencia'
}

const logTypeColor = {
	ERROR: 'red',
	INFO: 'blue',
	PHOTO: 'purple',
	SAVED: 'warning',
	SUCCESS: 'green',
	WARNING: 'orange'
}

export const setLogTypeColor = (logType) => {
	const logTypeFound = findType(logTypeEng, logTypeSpa, logType);

	return logTypeColor[logTypeFound];
}
