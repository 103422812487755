import React, { useEffect, useState } from 'react';

// Packages
import { Typography, Space, Collapse, Divider } from 'antd';
import PropTypes from 'prop-types';

// Components
import LogsProcess from './LogsProcess';

// Utils
import { setPayloadProcessColor } from '../../utils/payloadsProcess';

const LogData = (props) => {
	const { logData } = props;

	const [processItems, setProcessItems] = useState([]);

	const createProcessItems = (processes) => {
		let processItems = [];

		processes.forEach((process, index) => {
			let processItem = {
				key: index,
				label: (
					<Typography.Text strong style={{ color: setPayloadProcessColor(process.process_state) }}>{process.process_state}</Typography.Text>
				),
				children: (
					<Space direction='vertical' style={{ width: '100%', display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
						<LogsProcess processLogs={process.logs} />
					</Space>
				)
			};
			processItems.push(processItem);
		});

		setProcessItems(processItems);
	}

	useEffect(() => {
		createProcessItems(logData?.content?.process);
	}, [logData]);

	return (
		<div className='log-data-container'>
			<Typography.Title level={3} style={{ textAlign: 'center' }}>Contenido de logs</Typography.Title>

			<Space direction='vertical' style={{ width: '100%' }}>
				<Typography.Title level={4}>Información de la Tienda</Typography.Title>
				<Typography.Text>
					<strong>ID:</strong> <Typography.Text copyable>{logData?.content?.location?._id?.$oid || 'Sin Información'}</Typography.Text>
				</Typography.Text>
				<Typography.Text><strong>Nombre:</strong> {logData?.content?.location?.name || 'Sin Información'}</Typography.Text>
				<Typography.Text><strong>Código:</strong> {logData?.content?.location?.code || 'Sin Información'}</Typography.Text>
			</Space>

			<Divider />

			<Space direction='vertical' style={{ width: '100%' }}>
				<Typography.Title level={4}>Procesos</Typography.Title>
				<Collapse
					items={processItems}
				/>
			</Space>
		</div>
	)
}

LogData.propTypes = {
	logData: PropTypes.object.isRequired
}

export default LogData;
