import React from 'react';

// Packages
import { Button, Table } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

const LogsContentTable = (props) => {
	const { logs, setLogData } = props;

	const columns = [
		{
			title: 'File Name',
			dataIndex: 'name',
			key: 'name'
		},
		{
			title: 'View',
			key: 'view',
			render: (_, record) => (
				<Button type='primary' icon={<EyeFilled />} onClick={() => setLogData(record)}>
					View Logs
				</Button>
			),
		},
	];

	return (
		<div>
			<Table
				columns={columns}
				// pagination={{
				// 	position: ['none', 'none'],
				// }}
				dataSource={logs}
			/>
		</div>
	);
}

LogsContentTable.propTypes = {
	logs: PropTypes.array.isRequired,
	setLogData: PropTypes.func.isRequired
}

export default LogsContentTable;
