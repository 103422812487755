import { findType } from './findType';

const payloadProcessEng = {
	COMPLETE: 'Complete payload process',
	INTERRUPTED: 'Interrputed payload process',
	DELETED: 'Deleted payload process',
	PENDING: 'Payload is saved in device and is waiting to be sent',
	ERROR: 'An error occurred during the process',
	UNKNOWN: 'Unknown state payload process'
}

const payloadProcessSpa = {
	COMPLETE: 'Proceso de captura completo',
	INTERRUPTED: 'Proceso de captura interrumpido',
	DELETED: 'Proceso de captura eliminado',
	PENDING: 'La información de la captura se encuentra guardada y esperando para ser enviada.',
	ERROR: 'Un error ocurrió en el proceso',
	UNKNOWN: 'Proceso de captura de estatus desconocido'
}

const payloadProcessColor = {
	COMPLETE: 'green',
	INTERRUPTED: 'purple',
	DELETED: 'red',
	PENDING: 'orange',
	ERROR: 'red',
	UNKNOWN: 'black'
}

export const setPayloadProcessColor = (payloadProcess) => {
	const payloadProcessFound = findType(payloadProcessEng, payloadProcessSpa, payloadProcess);

	return payloadProcessColor[payloadProcessFound];
}
