/**
 * @param {object} engValuesObj - Object with values in english.
 * @param {object} spaValuesObj - Object with values in spanish.
 * @param {string} valueToFind - Value to find in the objects.
 */
export const findType = (engValuesObj, spaValuesObj, valueToFind) => {
	const engKeys = Object.keys(engValuesObj);
	const spaKeys = Object.keys(spaValuesObj);

	const engKey = engKeys.find(key => engValuesObj[key] === valueToFind);
	if (engKey !== undefined) {
		return engKey;
	}

	const spaKey = spaKeys.find(key => spaValuesObj[key] === valueToFind);
	if (spaKey !== undefined) {
		return spaKey;
	}

	return null; // Value not found
};
