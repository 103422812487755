import React from 'react';

// Packages
import { BrowserRouter as  Router, Routes, Route, Navigate  } from 'react-router-dom';
import { Layout } from 'antd';

// Components
import Authentication from './components/main/Authentication';
import Header from './components/main/Header';
import Footer from './components/main/Footer';
import PrivateRoute from './router/PrivateRoute';
import LogsView from './components/logs/LogsView';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Actions
import { user_token_check } from './actions/authActions';

user_token_check (store);

const App = () => {
	return (
		<Provider store= { store }>
			<Router>
				<Layout>
					<Header />

					<Layout.Content className='content'>
						<Routes>
							<Route path='/auth' element={ <Authentication /> } exact />

							<Route element={ <PrivateRoute /> } exact>
								<Route path='/view' element={ <LogsView /> } exact />
							</Route>

							{/* Other */}
							<Route path='*' element={ <Navigate to='/view' replace /> } />
						</Routes>
					</Layout.Content>

					<Footer />
				</Layout>
			</Router>
		</Provider>
	);
}

export default App;
